<template>
  <div id="app" v-bind:class="{ scrollHidden: modalActive }" v-on:scroll="handleScroll">
    <div class="preloader" v-if="!loaded">
      <div class="loadingImg"></div>
    </div>

    <header id="header" class="transparent-header">
      <div id="header-wrap" v-bind:class="{ 'floating': floatingMenu }">
        <div class="topBanner"><strong>XMAS SALE!</strong> 40% OFF ALL GAMES &amp; ESCAPE KITS</div>
        <div class="container topHeader">
          <div class="header-row">

            <nav class="primary-menu col-4">
              <ul class="menu-container justify-content-lg-end">
                <li class="menu-item"><a v-bind:href="(getRouteName == 'login' ? 'https://cursed-tales.com/' : '')+'#digital-games'" class="menu-link"><div>Digital Games</div></a></li>
                <li class="menu-item menuItemReviews"><a v-bind:href="(getRouteName == 'login' ? 'https://cursed-tales.com/' : '')+'#printables'" class="menu-link"><div>Printables</div></a></li>
              </ul>
            </nav>

            <div id="logo" class="col-4 me-lg-0">
              <router-link :to="{ name: 'home'}" class="logo-floating"><img v-bind:src="baseUrl + 'img/logoIcon-Red.png'" alt="Cursed Tales"></router-link>
              <router-link :to="{ name: 'home'}" class="logo-transparent"><img v-bind:src="baseUrl + 'img/logoIcon-White.png'" alt="Cursed Tales"></router-link>
              <router-link :to="{ name: 'home'}" class="logo-full"><img v-bind:src="baseUrl + 'img/logo2.png'" alt="Cursed Tales"></router-link>
            </div>

            <!--
            <div id="primary-menu-trigger">
              <svg class="svg-trigger" viewBox="0 0 100 100"><path d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"></path><path d="m 30,50 h 40"></path><path d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"></path></svg>
            </div>
            -->

            <div class="headerSocials">
                <a href="https://www.facebook.com/thecursedtales" target="_blank" class="socialLink"><div><i class="icon-facebook-sign"></i></div></a>
                <a href="https://www.instagram.com/cursed_tales_/" target="_blank" class="socialLink"><div><i class="icon-instagram2"></i></div></a>
            </div>

            <nav class="primary-menu col-4">
              <ul class="menu-container justify-content-lg-start">
                <li class="menu-item menuItemReviews"><a v-bind:href="(getRouteName == 'login' ? 'https://cursed-tales.com/' : '')+'#gift'" class="menu-link"><div>Gift Vouchers</div></a></li>
                <li class="menu-item"><router-link :to="{ name: 'account'}" class="menu-link"><div>Account</div></router-link></li>
                <li class="menu-item socials-item">
                  <a href="https://www.facebook.com/thecursedtales" target="_blank" class="menu-link"><div><i class="icon-facebook-sign"></i></div></a>
                  <a href="https://www.instagram.com/cursed_tales_/" target="_blank" class="menu-link"><div><i class="icon-instagram2"></i></div></a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>

    <router-view v-if="loaded" v-on:modal-active="modalActive=$event"></router-view>

    <footer id="footer" class="dark">
      <div id="copyrights" class="bg-transparent pb-4">
        <div class="container clearfix">

          <div class="row col-mb-30">
            <div class="col-4 footerLogo">
              <img src="https://cursed-tales.com/assets-sales/img/logo-white.png" alt="Logo">
            </div>
            <div class="col-8 subscribe">
              <p>Subscribe for updates</p>
              <p class="emailSuccess" v-if="emailSuccess">Thank you for subscribing!</p>
              <div class="emailForm" v-if="!emailSuccess">
                <input type="text" v-model="emailAddress" placeholder="Enter email address" v-bind:class="{ error: !emailValid }">
                <button type="submit" v-on:click.stop.prevent="emailSubmit()">
                  <span v-if="!emailLoading"><i class="icon-email2"></i> Subscribe</span>
                  <span v-if="emailLoading"><i class="icon-line-loader icon-spin"></i></span>
                </button>
              </div>
              <p class="emailError" v-if="!emailValid">Please enter a valid email address</p>
            </div>
          </div>

          <div class="row justify-content-between col-mb-30" style="margin-top: 40px">
            <div class="col-12 col-md-auto text-center text-md-start">
              <span class="font-primary">&copy; Cursed Tales. 2023. All Rights Reserved.</span>
            </div>

            <div class="col-12 col-md-auto text-center text-md-end">
              <div class="copyrights-menu copyright-links clearfix">
                  <a href="mailto:hello@cursed-tales.com" target="_blank"><i class="icon-email2"></i></a>
                  <a href="https://www.facebook.com/thecursedtales" target="_blank"><i class="icon-facebook-sign"></i></a>
                  <a href="https://www.instagram.com/cursed_tales_/" target="_blank"><i class="icon-instagram2"></i></a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </footer>

    <!--
    <div class="socialBar">
      <div class="fb-like" data-href="https://www.facebook.com/thecursedtales" data-width="" data-layout="standard" data-action="like" data-size="large" data-share="true"></div>
    </div>
    -->

    <div class="signupOverlay" v-if="showDiscountModal"></div>
    <div class="signupModal" v-if="showDiscountModal">
      <i class="icon-times closeModal" v-on:click="showDiscountModal=false"></i>
      <h2 v-if="!emailSuccess">Unlock a 20% off discount code</h2>
      <div class="subscribe">
        <p class="emailSuccess" v-if="emailSuccess">Thank you!<br>Your 20% off code is:<br><strong>FEB20</strong></p>
        <div class="emailForm" v-if="!emailSuccess">
          <input type="text" v-model="emailAddress" placeholder="Enter email address" v-bind:class="{ error: !emailValid }">
          <button type="submit" v-on:click.stop.prevent="emailSubmit()">
            <span v-if="!emailLoading">Yes, Unlock <span class="discountLabel">Discount</span></span>
            <span v-if="emailLoading"><i class="icon-line-loader icon-spin"></i></span>
          </button>
          <p class="emailError" v-if="!emailValid">Please enter a valid email address</p>
          <p class="disclaimer">By entering your email address you agree to be updated with news on future tales</p>
        </div>
        <a href="#" v-on:click.stop.prevent="showDiscountModal=false" v-if="!emailSuccess" class="notNow">Not now, thank you</a>
        <a href="#" v-on:click.stop.prevent="showDiscountModal=false" v-if="emailSuccess" class="closeNow">Head to website <i class="icon-hand-point-right"></i></a>
      </div>
    </div>
    <div class="clear"></div>
  </div>
</template>


<script>
import axios from 'axios'

import { mapGetters } from 'vuex'

export default {
  name: 'App',
  data () {
    return {
      loaded: false,
      baseUrl: "https://cursed-tales.com/assets-sales/",
      auth: null,

      trackerInterval: null,

      scrollPosition: 0,
      scrollDirection: null,

      showBanner: true,
      showDiscountModal: false,

      floatingMenu: false,
      modalActive: false,

      emailAddress: this.$developer ? "adamwalker297@gmail.com" : null,
      emailValid: true,
      emailSubmitted: false,
      emailSuccess: false,
      emailLoading: false,
    }
  },
  computed: {
    getRouteName () {
      return this.$route.name
    },
    getAuth () {
      return this.$store.getters.getAuth
    },
    getTales () {
      return this.$store.getters.getTalesData
    },
    ...mapGetters(['getAuth','getTalesData']),
  },
  methods: {
    validEmail: function (email) {
        let regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regEx.test(email);
    },
    checkValid() {
        this.emailValid = false
        if (!this.emailSubmitted || !this.emailAddress || this.validEmail(this.emailAddress))
            this.emailValid = true
    },
    emailSubmit() {
        this.emailSubmitted = true
        this.emailValid = true
        this.emailLoading = false
        if (!this.validEmail(this.emailAddress))
            this.emailValid = false

        if (this.emailAddress && this.emailValid && !this.emailLoading) {
            this.emailLoading = true
            axios
                .post('https://cursed-tales.com/subscribe', { email: this.emailAddress })
                .then(response => {
                    this.pushMfqVariable("SUBSCRIBED", "POPUP");
                    this.$fbq('track', 'Lead');
                    console.log("response", response)
                    this.emailSuccess = true
                    this.emailLoading = false
                })
                .catch(error => {
                    this.emailSuccess = true
                    this.emailLoading = false
                    console.log(error)
                })
        }
    },
    timeTracker() {
      let num = 1
      this.trackerInterval = setInterval(() => {
        if (this.$fbq)
          this.$fbq('trackCustom', 'TimeOnSite', { duration: num * 30 })
        num++

        if (num > 6) {
          clearInterval(this.trackerInterval);
          this.trackerInterval = null;
        }
      }, 30000);
    },
    handleScroll($event) {
      //console.log($event.target.scrollTop)
      /*
      if (this.scrollPosition) {
        if (window.scrollY > this.scrollPosition)
          console.log("scrolling down...")
        else
          console.log("scrolling up...")
      }
      */

      this.showBanner = $event.target.scrollToplY > 200 ? false : true
      //this.floatingMenu = $event.target.scrollTop > 500 ? true : false
      this.scrollPosition = $event.target.scrollTop;
    }
  },
  watch: {
    getTales(val) {
      this.loaded = val.loaded;
      console.log("getTales... ", val);
    },
  },
  mounted: function () {
    this.timeTracker();

    this.$store.dispatch("getTales", sessionStorage.getItem("Cursed_CustomerReference") || "")
    //window.addEventListener("scroll", this.handleScroll)

    this.$cookies.config('7d')

    if (this.$showPopup) {
      setTimeout(() => {
        this.showDiscountModal = true
        axios
            .get('https://cursed-tales.com/popups_update')
      }, 30000);
    }

    if (this.$route.query.adref) {
      this.pushMfqVariable("ADSET", this.$route.query.adref);
    }
  },
  destroyed: function () {
    //window.removeEventListener("scroll", this.handleScroll);
  },

}
</script>

<style>
  .preloader {
    position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #000;
      z-index: 399;
      text-align: center;
  }
  .loadingImg {
    margin: 15% auto;
    width: 100px;
    height: 100px;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    background: url('https://cursed-tales.com/assets-sales/img/loader.png');
  }
  .topHeader {
    position: relative;
  }
  .topBanner {
    position: relative;
    width: 100%;
    background: #163c93;
    text-align: center;
    color: #fff;
    letter-spacing: 5px;
    padding: 5px 0;
    text-shadow: 1px 1px black;
  }
  .topBanner strong {
    color: #f25757;
    font-weight: 900;
  }
  .socialBar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    min-height: 40px;
    background: #fff;
    text-align: center;
    color: #000;
    letter-spacing: 5px;
    padding: 5px 0;
    z-index: 100;
  }
  #app {
    position: relative;
    height: 100%;
    /* overflow-y: scroll; */
  }
  #app.scrollHidden {
    overflow-y: hidden;
  }

  @media (max-width: 992px) {
    .topBanner span {
      display: none;
    }
  }
</style>
